import api from '../backend-api.js';
// import moment from "moment";
export default {
    getAllUsers: async () => {
        return (await api.getData('/user')).data
    },
    createUser: async (newUser) => {
        await api.postData('/user', newUser);
    },
    deleteUser: async (user) => {
        return api.deleteData('/user/' + user.id);
    },
    updateUser: async (user) => {
        return api.putData('/user/' + user.id, user);
    },
    getAllFacilities: async () => {
        return (await api.getData('/facilities')).data;
    },
    getBalance: async ({ userId, startDate, endDate, facilityIds }) => {
        return (await api.getData(`/company/users/solde/${userId}?start_date=${startDate}&end_date=${endDate}&facilities=${facilityIds}`)).data;
    },
    getBalancesByFacilities: async ({ facilities, startDate, endDate }) => {
        console.log("Get balances for facilities ", facilities, " from ", startDate, " to ", endDate)
        /* const data = [
            {
                id: 1,
                facilityId: 2,
                first_name: 'Antoine',
                last_name: 'Lebrun',
                mail: 'antoinelmh@hotmail.fr',
                matricule: 'A35E',
                badgeIds: ['123456789', '234567891', '345678912'],
                consigne: 15,
                deconsigne: 25,
                soldeOnPeriod: 0,
                blocages: 5,
                notReturnedContainers: 4,
                lastOperation: lastTransaction1
            },
            {
                id: 2,
                facilityId: 2,
                first_name: 'Killian',
                last_name: 'Blanchard',
                matricule: 'B35F',
                badgeIds: ['9843'],
                consigne: 20,
                deconsigne: 10,
                soldeOnPeriod: 4,
                blocages: 2,
                notReturnedContainers: 1,
                lastOperation: lastTransaction2
            },
            {
                id: 3,
                facilityId: 36,
                first_name: 'Guillaume',
                last_name: 'Bourdonnay',
                matricule: 'C12PRHZ',
                badgeIds: ['1234'],
                consigne: 8,
                deconsigne: 8,
                soldeOnPeriod: 0,
                blocages: 1,
                notReturnedContainers: 0,
                lastOperation: lastTransaction2
            },
            {
                id: 3,
                facilityId: 36,
                first_name: 'Guillaume',
                last_name: 'Bourdonnay',
                matricule: 'C12PRHZ',
                badgeIds: ['1234'],
                consigne: 8,
                deconsigne: 8,
                soldeOnPeriod: 0,
                blocages: 1,
                notReturnedContainers: 0,
                lastOperation: lastTransaction2
            },
            {
                id: 3,
                facilityId: 36,
                first_name: 'Guillaume',
                last_name: 'Bourdonnay',
                matricule: 'C12PRHZ',
                badgeIds: ['1234'],
                consigne: 8,
                deconsigne: 8,
                soldeOnPeriod: 0,
                blocages: 1,
                notReturnedContainers: 0,
                lastOperation: lastTransaction2
            },
            {
                id: 3,
                facilityId: 36,
                first_name: 'Guillaume',
                last_name: 'Bourdonnay',
                matricule: 'C12PRHZ',
                badgeIds: ['1234'],
                consigne: 8,
                deconsigne: 8,
                soldeOnPeriod: 0,
                blocages: 1,
                notReturnedContainers: 0,
                lastOperation: lastTransaction2
            },
            {
                id: 3,
                facilityId: 36,
                first_name: 'Guillaume',
                last_name: 'Bourdonnay',
                matricule: 'C12PRHZ',
                badgeIds: ['1234'],
                consigne: 8,
                deconsigne: 8,
                soldeOnPeriod: 0,
                blocages: 1,
                notReturnedContainers: 0,
                lastOperation: lastTransaction2
            },
            {
                id: 3,
                facilityId: 36,
                first_name: 'Guillaume',
                last_name: 'Bourdonnay',
                matricule: 'C12PRHZ',
                badgeIds: ['1234'],
                consigne: 8,
                deconsigne: 8,
                soldeOnPeriod: 0,
                blocages: 1,
                notReturnedContainers: 0,
                lastOperation: lastTransaction2
            },
            {
                id: 3,
                facilityId: 36,
                first_name: 'Guillaume',
                last_name: 'Bourdonnay',
                matricule: 'C12PRHZ',
                badgeIds: ['1234'],
                consigne: 8,
                deconsigne: 8,
                soldeOnPeriod: 0,
                blocages: 1,
                notReturnedContainers: 0,
                lastOperation: lastTransaction2
            },
            {
                id: 3,
                facilityId: 36,
                first_name: 'Guillaume',
                last_name: 'Bourdonnay',
                matricule: 'C12PRHZ',
                badgeIds: ['1234'],
                consigne: 8,
                deconsigne: 8,
                soldeOnPeriod: 0,
                blocages: 1,
                notReturnedContainers: 0,
                lastOperation: lastTransaction2
            },
            {
                id: 3,
                facilityId: 36,
                first_name: 'Guillaume',
                last_name: 'Bourdonnay',
                matricule: 'C12PRHZ',
                badgeIds: ['1234'],
                consigne: 8,
                deconsigne: 8,
                soldeOnPeriod: 0,
                blocages: 1,
                notReturnedContainers: 0,
                lastOperation: lastTransaction2
            },
            {
                id: 3,
                facilityId: 36,
                first_name: 'Guillaume',
                last_name: 'Bourdonnay',
                matricule: 'C12PRHZ',
                badgeIds: ['1234'],
                consigne: 8,
                deconsigne: 8,
                soldeOnPeriod: 0,
                blocages: 1,
                notReturnedContainers: 0,
                lastOperation: lastTransaction2
            },
            {
                id: 3,
                facilityId: 36,
                first_name: 'Guillaume',
                last_name: 'Bourdonnay',
                matricule: 'C12PRHZ',
                badgeIds: ['1234'],
                consigne: 8,
                deconsigne: 8,
                soldeOnPeriod: 0,
                blocages: 1,
                notReturnedContainers: 0,
                lastOperation: lastTransaction2
            },
            {
                id: 3,
                facilityId: 36,
                first_name: 'Guillaume',
                last_name: 'Bourdonnay',
                matricule: 'C12PRHZ',
                badgeIds: ['1234'],
                consigne: 8,
                deconsigne: 8,
                soldeOnPeriod: 0,
                blocages: 1,
                notReturnedContainers: 0,
                lastOperation: lastTransaction2
            }
        ]*/
        return (await api.getData(`/company/users/solde?start_date=${startDate}&end_date=${endDate}&facilities=${facilities.join(",")}`)).data;
    },
    getConviveOperations: async ({ userId, startDate, endDate }) => {
        console.log("Get operations for convive ", userId, " from ", startDate, " to ", endDate)
        return (await api.getData(`/operations/user/${userId}?start_date=${startDate}&end_date=${endDate}`)).data;

    },
    getConvivePurchases: async ({ userId, startDate, endDate }) => {
        console.log("Get purchases for convive ", userId, " from ", startDate, " to ", endDate)
        return (await api.getData(`/sale/company-user/${userId}?start_date=${startDate}&end_date=${endDate}`)).data;
    },
};
