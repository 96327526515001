import moment from "moment";

export const formatDate = (datestr, format) => {
    if (datestr != null) {
      return moment(String(datestr)).format(format);
    } else {
      return "";
    }
};

export const formatDateToLocal = (dateString) => {
    return moment(dateString).local().format('DD MMM YYYY HH:mm');
};