import api from '../backend-api.js';

export default {
    getAll: async () => {
        let machines = (await api.getData('/machine/')).data;
        return machines;
    },
    getAllTypes: async () => {
        return (await api.getData('/machine/types')).data;
    },
    getFacilitiesForMachines: async () => {
        return (await api.getData('/machine/facilities')).data;
    },
    getFacilitiesForMachine: async (machine) => {
        return (await api.getData('/machine/' + machine.id + '/facilities')).data;
    },
    getContainerAttributionsForMachine: async (machineId) => {
        return (await api.getData('/machine/' + machineId + '/container-attributions')).data;
    },
    getSlotsForMachine: async (machineId) => {
        return (await api.getData('/machine/' + machineId + '/slots')).data;
    },
    getSlotAttributionsForMachine: async (machineId) => {
        return (await api.getData('/machine/' + machineId + '/slot-attributions')).data;
    },
    getCompanyUsersForMachine: async (machine) => {
        return (await api.getData('/machine/' + machine.id + '/companyUsers')).data;
    },
    update: async (machine) => {
        return (await api.putData('/machine/' + machine.id, machine)).data;
    },
    updatePassword: async (machine) => {
        return (await api.putData('/machine/' + machine.id + '/password', machine.apiPassword, {
            headers: { "Content-Type": "text/plain" },
        })).data;
    },
    updateFacilities: async (machine) => {
        console.log('/machine/' + machine.id + '/facilities', machine.facilityIds)
        return api.postData('/machine/' + machine.id + '/facilities', machine.facilityIds);
    },
    updateSlots: async (machineId, slotAttributions) => {
        return (await api.postData('/machine/' + machineId + '/slot-attributions', slotAttributions)).data;
    },
    delete: async (machine) => {
        return api.deleteData('/machine/' + machine.id);
    },
    create: async (machine) => {
        return (await api.postData('/machine/', machine)).data;
    },
};
